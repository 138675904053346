import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import { connect } from '../../../common/components/runtime-context';
import withExperiment from '../../../common/hoc/with-experiment';
import { getLayoutPostSize } from '../../../common/selectors/app-settings-selectors';
import CreatePostButtonMobile from './create-post-button-mobile';
import PageTabs, { ALIGNMENT } from './page-tabs';
import styles from './my-posts-frame-mobile.scss';

const MyPostsFrameMobile = ({
  children,
  layoutPostSize,
  isLiveSiteEditorDisabled,
}) => (
  <section
    className={styles.root}
    style={{ '--width': layoutPostSize ? `${layoutPostSize}px` : undefined }}
  >
    <PageTabs alignment={ALIGNMENT.center} />
    <main className={styles.main}>
      <div className={styles.createPostButton}>
        {!isLiveSiteEditorDisabled && <CreatePostButtonMobile />}
      </div>
      {children}
    </main>
  </section>
);

MyPostsFrameMobile.propTypes = {
  layoutPostSize: PropTypes.number.isRequired,
  isLiveSiteEditorDisabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, _actions, host) => ({
  layoutPostSize: getLayoutPostSize({
    state,
    hostWidth: host.dimensions.width || undefined,
  }),
  isLiveSiteEditingEnabled: !ownProps.isLiveSiteEditorDisabled,
});

export default flowRight(
  withExperiment({
    isLiveSiteEditorDisabled: EXPERIMENTS.LIVE_SITE_EDITOR_DEPRECATION,
  }),
  connect(mapRuntimeToProps),
)(MyPostsFrameMobile);
